<template>
  <div class="consolAccount">
    <div class="title">
      <h2>Account</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" label-width="120px" :model="consoleAccount">
              <el-form-item label="Account ID">
                <el-input
                  v-model="consoleAccount.accountId"
                  placeholder="account id regex here ..."
                ></el-input>
              </el-form-item>
              <el-form-item label="Account Name">
                <el-input
                  v-model="consoleAccount.accountName"
                  placeholder="account name regex here ..."
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="addClick">Create</el-button>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              style="width:100%;"
              height="70vmin"
              stripe
              border
              :data="consoleAccountList"
              highlight-current-row
            >
              <el-table-column type="expand">
                <template slot-scope="scopes">
                  <el-table :data="scopes.row.roles" border stripe>
                    <el-table-column label="Role Id" prop="id" align="center"></el-table-column>
                    <el-table-column
                      label="Role Name"
                      prop="roleName"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      label="Role Desc"
                      prop="roleDesc"
                      align="center"
                    ></el-table-column>
                    <!-- <el-table-column label="createTime" prop="Create Time"></el-table-column> -->
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column label="Account ID" prop="accountId" align="center"></el-table-column>
              <el-table-column
                label="Account Name"
                prop="username"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column label="Enable" prop="enable" align="center" min-width="70">
              </el-table-column>
              <el-table-column
                label="Open Source"
                prop="isOpenAllAdv"
                align="center"
                min-width="70"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.isOpenAllAdv > 0">Yes</span>
                  <span v-else>No</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Open Affiliate"
                prop="isOpenAllAff"
                align="center"
                min-width="70"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.isOpenAllAff > 0">Yes</span>
                  <span v-else>No</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Description"
                prop="description"
                align="center"
              ></el-table-column>
              <el-table-column label="Action" align="center">
                <template slot-scope="scope">
                  <el-dropdown placement="right-start">
                    <el-button type="primary" size="small">
                      More Action<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <el-button
                          type="primary"
                          size="small"
                          :style="{ width: '100%', marginTop: '5px' }"
                          @click="updateClick(scope.row)"
                          >Add Or Update Role
                        </el-button>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-button
                          type="primary"
                          size="small"
                          :style="{ width: '100%', marginTop: '5px' }"
                          @click="updateAccountClick(scope.row)"
                          >Update
                        </el-button>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-button
                          type="danger"
                          size="small"
                          :style="{ width: '100%', marginTop: '5px' }"
                          @click="delAccountClick(scope.row)"
                          >Delete
                        </el-button>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 模块框 -->
    <el-dialog
      :title="dialogType === 'new' ? 'Create Account' : 'Update Account'"
      width="60%"
      :visible.sync="dialogVisible"
    >
      <el-form label-position="left" :model="account" label-width="110px">
        <template v-if="dialogType === 'new'">
          <el-row>
            <el-col :lg="11" :md="20">
              <el-form-item label="Account Name">
                <el-input
                  v-model="account.username"
                  placeholder="affiliate name here ..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="Password">
                <el-input
                  style="width:222px;"
                  v-model="account.password"
                  placeholder="affiliate password here ..."
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="11" :md="20">
              <el-form-item label="Description">
                <el-input
                  v-model="account.description"
                  placeholder="description here ..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="Account Role" label-width="110px">
                <el-select v-model="account.roles" multiple>
                  <el-option
                    v-for="item in roleOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="11" :md="20">
              <el-form-item label="Open Sources" label-width="110px">
                <el-select v-model="account.isOpenAllAdv" placeholder="Sources">
                  <el-option
                    v-for="item in isOpenAllAdvOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="20" :offset="1">
              <el-form-item label="Open Affiliates" label-width="110px">
                <el-select v-model="account.isOpenAllAff" placeholder="Affiliates">
                  <el-option
                    v-for="item in isOpenAllAffOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <div v-if="dialogType === 'edit'">
          <el-row>
            <el-col :lg="8" :md="20">
              <el-form-item label="Description" label-width="90px">
                <el-input
                  v-model="account.description"
                  placeholder="description here..."
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="7" :md="20" :offset="1">
              <el-form-item label="Open Sources" label-width="110px">
                <el-select v-model="account.isOpenAllAdv" placeholder="Sources">
                  <el-option
                    v-for="item in isOpenAllAdvOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="7" :md="20" :offset="1">
              <el-form-item label="Open Affiliates" label-width="110px">
                <el-select v-model="account.isOpenAllAff" placeholder="Affiliates">
                  <el-option
                    v-for="item in isOpenAllAffOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :lg="8" :md="20">
              <el-form-item label="Enable" label-width="90px">
                <el-switch
                  v-model="account.enable"
                  active-value="ENABLE"
                  inactive-value="DISABLE"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改角色模块框 -->
    <el-dialog title="Update Role" width="40%" :visible.sync="updateVisible">
      <el-form :model="updateRole" label-position="left" label-width="100px">
        <el-row>
          <el-col :lg="10" :md="20">
            <el-form-item label="Account Role" label-width="100px">
              <el-select v-model="updateRole.roleId" style="width:240px;" multiple>
                <el-option
                  v-for="item in roleOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmUpdateClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 删除提示框 -->
    <el-dialog title="Delete" :visible.sync="delVisible" width="300px" center append-to-body>
      <div class="del-dialog-cnt">Confirm to delete ?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delVisible = false">cancel</el-button>
        <el-button type="primary" @click="delRow">confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    bindRole,
    deleteAccount,
    getAccountList,
    getRoleList,
    insertAccount,
    updateAccount,
  } from 'api/account/index';
  import Pagination from '@/components/pagination';
  import { myMixin } from '@/mixins/mixins.js';

  const defaultAccount = {
    username: '',
    password: '',
    description: '',
    roles: [],
    isOpenAllAdv: 0,
    isOpenAllAff: 0,
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        pageSize: 100,
        accountId: null,
        delVisible: false,
        updateAccountVisible: false,
        isOpenAllAffOptions: [
          {
            label: 'yes',
            value: 1,
          },
          {
            label: 'no',
            value: 0,
          },
        ],
        isOpenAllAdvOptions: [
          {
            label: 'yes',
            value: 1,
          },
          {
            label: 'no',
            value: 0,
          },
        ],
        currentPage: 1,
        roleOptions: [],
        consoleAccount: {
          accountId: null,
          username: null,
        },
        total: null,
        listLoading: false,
        userNameValue: '',
        statusOptions: [
          {
            label: 'ENABLE',
            value: 'ENABLE',
          },
          {
            label: 'DISABLE',
            value: 'DISABLE',
          },
        ],
        consoleAccountList: [],
        account: Object.assign({}, defaultAccount),
        updateRole: {
          roleId: [],
        },
        dialogType: 'new',
        dialogVisible: false,
        updateVisible: false,
        id: '',
      };
    },
    mounted() {
      this.getDataList();
    },
    methods: {
      searchListClick(page) {
        this.listLoading = true;
        let param = {};
        if (this.consoleAccount.accountId != null) {
          param['accountId'] = this.consoleAccount.accountId;
        }
        if (this.consoleAccount.accountName != null) {
          param['accountName'] = this.consoleAccount.accountName;
        }
        if (page) {
          this.currentPage = 1;
        }
        param['page'] = this.currentPage;
        param['pageSize'] = this.pageSize;
        getAccountList(param).then((response) => {
          this.listLoading = false;
          if (response.code == 200) {
            this.consoleAccountList = response.result;
            this.total = response.total;
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      },
      getDataList() {
        let param = {};
        getRoleList(param).then((response) => {
          if (response.code === 200) {
            let array = response.result;
            let rolesMap = [];
            array.forEach((item) => {
              rolesMap.push({
                value: item.id,
                label: item.roleName,
              });
            });
            this.roleOptions = rolesMap;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      addClick() {
        this.account = Object.assign({}, defaultAccount);
        this.dialogType = 'new';
        this.dialogVisible = true;
      },
      updateAccountClick(row) {
        this.account = {
          id: row.accountId,
          description: row.description,
          isOpenAllAdv: row.isOpenAllAdv,
          isOpenAllAff: row.isOpenAllAff,
          enable: row.enable,
        };
        this.dialogType = 'edit';
        this.dialogVisible = true;
      },
      delAccountClick(row) {
        this.accountId = row.accountId;
        this.delVisible = true;
      },
      delRow() {
        deleteAccount(this.accountId).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.searchListClick();
            this.delVisible = false;
          } else {
            this.$message.error('Error' + response.message);
          }
        });
      },
      confirmClick() {
        const isEdit = this.dialogType === 'edit';
        if (!isEdit) {
          insertAccount({ ...this.account }).then((response) => {
            if (response.code === 200) {
              this.$message({
                message: 'Add Success',
                type: 'success',
              });
              this.searchListClick();
              this.dialogVisible = false;
            } else {
              this.$message.error('Error' + response.message);
            }
          });
        } else {
          updateAccount({ ...this.account }).then((response) => {
            if (response.code === 200) {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
              this.searchListClick();
              this.dialogVisible = false;
            } else {
              this.$message.error('Error' + response.message);
            }
          });
        }
      },
      updateClick(row) {
        this.updateRole.roleId = [];
        this.accountId = row.accountId;
        for (var i = 0; i < row.roles.length; i++) {
          this.updateRole.roleId.push(row.roles[i].id);
        }
        this.updateVisible = true;
      },
      confirmUpdateClick() {
        if (this.updateRole.roleId.length == 0) {
          this.$message.error('一个用户至少拥有一个角色');
          return;
        }
        bindRole(this.accountId, [...this.updateRole.roleId]).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: response.message,
              type: 'success',
            });
            this.searchListClick();
            this.updateVisible = false;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.searchListClick();
      },
    },
  };
</script>
<style lang="scss" scoped></style>
